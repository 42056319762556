import React from "react";
import FourOnFour from "../react/pages/404/";
import { Providers } from "../react/common/layouts/Layout";

interface FourOhFourProps {}

const isBrowser = typeof window !== "undefined" && window;

export default function FourOhFour({}: FourOhFourProps): React.ReactElement {
  return (
    isBrowser && (
      <Providers>
        <FourOnFour />
      </Providers>
    )
  );
}
